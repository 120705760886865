import React, { useState, useEffect, useRef } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled, { css } from 'styled-components'
import { useInterval, useMount } from 'react-use'
import moment from 'moment'
import ReactPlayer from 'react-player'
import DateTimePicker from 'react-datetime-picker'

import { Layout } from '../components'
import { container, padding, button } from '../styles/global'
import { media, useBreakpoint, isClient } from '../styles/utils'
import { formatDate, msToTime } from '../utils'

import silence from '../assets/audio/silence.mp3'

const apiURL = 'https://worldtimeapi.org/api/timezone/Australia/Brisbane'
const audioURL = 'https://pda-clients.s3-ap-southeast-2.amazonaws.com/NIGHT_SKY_SOUNDTRACK.mp3'


const Index = (props) => {

    const audioRef = useRef(false)
    
    const [active, setActive] = useState(false)
    const [launched, setLaunched] = useState(false)
    const [launchedText, setLaunchedText] = useState('Launch')   

    const [muted, setMuted] = useState(false)
    const [playingAudio, setPlayingAudio] = useState(false) 
    const [audioFileLoaded, setAudioFileLoaded] = useState(false)
    const [audioReady, setAudioReady] = useState(false)
    const [audioLoaded, setAudioLoaded] = useState(0) 
    const [audioProgress, setAudioProgress] = useState(0) 
    const [audioDuration, setAudioDuration] = useState(0) 
    
    const [startTime, setStartTime] = useState(0) 
    const [startLatency, setStartLatency] = useState(0) 
    const [playerLatencyStart, setPlayerLatencyStart] = useState(0) 
    const [playerLatency, setPlayerLatency] = useState(0) 
    
    const [timestamps, setTimestamps] = useState(null)
    const [offset, setOffset] = useState(0)
    const [updatedTime, setUpdatedTime] = useState(0)
    const [inputTime, setInputTime] = useState(moment().add(1,'hour').toDate());
    

    useEffect(() => {

        // need to call api twice to factor in initial connection start times
        fetch(apiURL).then( () => {
            getAPITime()
        })  

    }, []);


    // loop engine
    useInterval(() => {
       
        // start measuring player latency
        if(!active && audioFileLoaded && playingAudio && playerLatencyStart<1){
            setPlayerLatencyStart(performance.now())
        }

        // measure Player latency (launch button)
        if(!active && audioFileLoaded && audioProgress>0 && playerLatency<1){

            let latency = performance.now() - playerLatencyStart
            console.log('latency',latency);
            // fix latency to 500ms if can't measure it properly
            if(latency>500) latency = 500
            setPlayerLatency(latency.toFixed(4))
        }

        // Open app if audio ready to play and latency measured
        if(!launched && !active && audioProgress>0 && playerLatency>0){

            //reset default input time
            setInputTime(moment().add(5,'seconds').toDate())

            setPlayingAudio(false)
            setMuted(false) 
            audioSeek(0)
            setLaunched(true)
            
        }

        if(launched){

            // set offset device time
            const current = (Date.now()+offset)
            const t = formatDate(current)
            setUpdatedTime(t)

             // measure Player latency at play time
            if(active && audioProgress>0 && startLatency<1){
                setStartLatency((performance.now() - startTime).toFixed(4))
            }

             // PLAY AUDIO
            // - play audio if Input time is less than device time 
            // - factor in latency from player
            // - factor in offset from server 
            
            if(!active && !playingAudio && ((inputTime-playerLatency) <= (Date.now()+offset))  ){ 
    
                setMuted(false)
                setPlayingAudio(true) 
                audioSeek(0)
                setActive(true)
                setStartTime(performance.now())
            }
        }
 
    },50)

    
    
    const onLaunchButton = () => {

        setLaunchedText('Please Wait')

        setPlayingAudio(true)
        setMuted(true)
   
    }

    const onTimeSelectorChange = (val) => {
        setInputTime(val)
        setPlayingAudio(false)
        audioSeek(0)
    }

    const audioSeek = (sec) => {
        audioRef.current.seekTo(sec)
    }

    const onAudioReady = () => {
        //console.log('onAudioReady');
        setAudioReady(true)
    }

    const onAudioDuration = (val) => {
        //console.log('onAudioDuration',val);
        setAudioDuration(val)
    }

    const onAudioProgress = (val) => {
        //console.log('onAudioProgress',val);
        setAudioLoaded(val.loaded)
        setAudioProgress(val.played)
    }
    
    const onAudioEnded = () => {
        console.log('onAudioEnded');

    }

    const getAPITime = () => {
  
        const requestTime = performance.now()

        fetch(apiURL)
            .then(response => {
                return response.json()
            })  
            .then(data => {
                //console.log('data',data);
                processTime(requestTime,data) 
            })
    }

    const processTime = (requestTime,data) => {

        let td = {}

        td.now = Date.now()
        td.requestTime = requestTime
        td.resultTime = performance.now()     
        td.ttfb = (td.resultTime - td.requestTime)
        td.serverTime = new Date(data.utc_datetime).getTime()  //worldtimeapi    
        td.normalisedServerTime = td.serverTime +(td.ttfb/2)
        td.offset = parseFloat((td.normalisedServerTime - td.now).toFixed(2))

        setTimestamps(td)
        setOffset(td.offset)
    }

    const displayTime = () => {

        if(!timestamps) return

        let countdown = inputTime - (Date.now()+offset)

        const data = [   
            
            
            { 
                title: 'time entered',
                val: formatDate(inputTime),
                color: 'black'
            },     
            { 
                title: 'time countdown',
                val: countdown > 0 ? msToTime(countdown) : 0,
                color: 'black'
            },
            { 
                title: 'progress %',
                val: (audioProgress*100).toFixed(2),
                color: 'black'
            },
            { 
                title: 'actual Latency ms',
                val: startLatency,
                color: 'black'
            },
            { 
                title: 'measured Latency ms',
                val: playerLatency,
                color: 'black'
            },
            { 
                title: 'offset device time',
                val: updatedTime,
                color: 'black'
            },
            { 
                title: 'status',
                val: playingAudio ? 'playing' : 'not playing',
                color: 'black'
            },
            { 
                title: 'audio loaded %',
                val: (audioLoaded*100).toFixed(2),
                color: 'black'
            },  

               
            { 
                title: 'ttfb',
                val: `${timestamps.ttfb.toFixed(2)} ms`,
                color: 'grey'
            },
            { 
                title: 'server',
                val: formatDate(timestamps.serverTime),
                color: 'grey'
            },
            { 
                title: 'normalised',
                val: formatDate(timestamps.normalisedServerTime),
                color: 'grey'
            },
            { 
                title: 'device time',
                val: formatDate(timestamps.now),
                color: 'grey'
            },
            { 
                title: 'offset',
                val: `${offset} ms`,
                color: 'grey'
            },
            { 
                title: 'now',
                val: formatDate(Date.now()),
                color: 'grey'
            },
            
        ]

        const items = data.map((item, i) => {
            return (
                <Item key={i}>
                    <Subheading color={item.color}>{item.title}</Subheading>
                    <Text color={item.color}>{item.val}</Text>
                </Item>
            )
        })

        return (
            <Timestamps>{items}</Timestamps>
        )
    }




    


	return (
		<Layout
            // meta={data && data.seo}
        >
                <Wrapper>

                    <Container>

                        <Heading>Sunsuper Night Sky</Heading>

                        { !launched && (
                            <>
                                <LaunchButton onClick={() => onLaunchButton()}>{launchedText}</LaunchButton>
                                <Text>{`Audio Loaded: ${audioLoaded.toFixed(2)}`}</Text>
                                <Text>{`Audio Progress: ${(audioProgress*100).toFixed(2)}`}</Text>
                                <Text>{`Audio Ready: ${audioReady}`}</Text>  
                                {/* <Text>{`Audio Duration: ${audioDuration}`}</Text>      */}
                                <Text>{`player Latency: ${playerLatency}`}</Text>         
                            </>
                        )}


                        { launched && (
                            <>

                                <TimeForm>
                                    <Subheading>Enter Start Time</Subheading>
                                    <TimeSelector
                                        onChange={(val) => onTimeSelectorChange(val)} //
                                        value={inputTime}
                                        format={"y-MM-dd h:mm:ss a"}
                                    />
                                </TimeForm>

                                <MuteButton 
                                    onClick={() => setMuted(!muted)}
                                >
                                    {muted ? 'Muted' : 'Mute'}
                                </MuteButton>
                            </>
                        )}  

                        {/* <Silence 
                            src={silence} 
                            allow="autoplay" 
                            id="audio"
                        /> */}

                        <Audio 
                            ref={audioRef}
                            url={audioURL}
                            controls={true}
                            muted={muted} //
                            playing={playingAudio} //
                            loop={false}
                            volume={0.8}
                            // width={launched ? '90%' : '0px'}
                            // height={launched ? '50px' : '0px'}
                            width={'90%'}
                            height={'50px'}
                            progressInterval={100}
                            
                            onReady={ onAudioReady }
                            onEnded={ onAudioEnded }                  
                            onProgress={(e) => onAudioProgress(e)}

                            onPlay={()=>console.log('onPlay')}
                            onDuration={(e) => onAudioDuration(e)}
                            onBuffer={(e)=>console.log('onBuffer',e)}
                            onBufferEnd={()=>console.log('onBufferEnd')}
                            onSeek={(e)=>console.log('onSeek', e)}

                            config={{
                                file: {
                                    attributes: {
                                        preload: "auto",
                                        onCanPlayThrough: () => {
                                            setAudioFileLoaded(true)
                                            console.log('onCanPlayThrough')
                                        }
                                    },
                                },
                            }}
                            
                        />

                
                        { launched && displayTime() }

        

                    </Container>

               </Wrapper>
		</Layout>	
	)
}

// Shared

const Heading = styled.div``
const Subheading = styled.div`
    color: ${props => props.color}; 
`
const Text = styled.div`
    color: ${props => props.color}; 
`
const Item = styled.div``




// Layout

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	overflow: hidden;
    width: 100%;
`
const Container = styled.div`
	${container}
    ${padding}
    align-items: center;
    flex-direction: column;
    width: 500px; 

    ${media.phone`
        width: 100%;
	`}

    ${Heading}{
        margin: 50px 0;
        /* align-self: flex-start; */
    }

`

const LaunchButton = styled.div`
    ${button};
    border: 1px solid black;
    margin-bottom: 30px;
`

const MuteButton = styled.div`
    ${button};
    border: 1px solid black;
    margin-top: 40px;
`

const TimeForm = styled.div`

    ${Subheading}{
        color: grey;
        margin-bottom: 10px;
    }
`
const TimeSelector = styled(DateTimePicker)`
    align-self: center;
`

const Timestamps = styled.div`
    width: 100%;
    margin-top: 40px;
    font-size: 20px;
    line-height: 1.5em;
    display: flex;
    flex-direction: column;

    ${Item}{
        display: flex;
        width: 100%;
        margin-bottom: 20px;
        ${Subheading}{
            width: 50%;
            color: ${props => props.color};
            padding-right: 20px;
            text-align: right;
        }
        ${Text}{
            width: 50%;
            padding-left: 20px;
        }
    }
`

const Audio = styled(ReactPlayer)`
    /* visibility: hidden; */
    /* visibility: ${props => props.visibility}; */
    margin-top: 40px;
`
const Silence = styled.iframe`
    display: none;
`

export default Index